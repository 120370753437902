import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { ALL_PLANS } from 'my-core/subscription-utils';

import SubscriptionPlanItem from './SubscriptionPlanItem';

const useStyles = makeStyles(
  ({ breakpoints, spacing }) => ({
    root: {
      display: 'flex',
      minWidth: 0,
      justifyContent: 'center',
      gap: spacing(2),
    },
    plan: { flex: '1 0 0', minWidth: 230, maxWidth: 350, width: 260 },
    [breakpoints.down('sm')]: {
      root: { flexWrap: 'wrap' },
    },
  }),
  { name: 'SubscriptionPlanItems' },
);

export default function SubscriptionPlanItems(props) {
  const { allSelected, className, disabled, onClick, plansCtaProps, product, selectedPlan } = props;
  const classes = useStyles(props);

  return (
    <div className={classNames(className, classes.root)}>
      {ALL_PLANS.map(plan => (
        <SubscriptionPlanItem
          key={plan}
          className={classes.plan}
          ctaProps={plansCtaProps?.[plan]}
          disabled={disabled}
          onClick={onClick}
          plan={plan}
          product={product}
          selected={allSelected || selectedPlan === plan}
        />
      ))}
    </div>
  );
}

SubscriptionPlanItems.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  plansCtaProps: PropTypes.shape({ monthly: PropTypes.object.isRequired, yearly: PropTypes.object.isRequired }),
  selectedPlan: PropTypes.string,
};
