import { useCallback, useState } from 'react';

import { COUNTRIES, REFERRAL_DISCOUNT_AMOUNT } from 'my-core/constants';
import { addDate, formatDate } from 'my-core/date-utils';
import { calculateDiscount } from 'my-core/discount-code-utils';
import { SUBSCRIPTION_PRODUCT_TYPES_CONFIG } from 'my-core/subscription-utils';
import { numberToCurrency } from 'my-utils';

import CheckoutLineItems from 'my-components/Checkout/CheckoutLineItems';
import DiscountCodeInput from 'my-components/DiscountCodeInput';
import PaymentForm from 'my-components/PaymentForm';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

function SubscriptionCheckoutPayment({
  defaultDiscountCodeQuery,
  discountCode: discountCodeProp,
  intent,
  noDialog,
  onClose,
  onSubmit,
  paymentIntent,
  paymentMethod,
  plan,
  product,
  purchaseError,
  purchasing,
  user,
}) {
  const countryCode = user.country_code;

  const [discountCodeState, setDiscountCode] = useState(discountCodeProp);
  const discountCode = discountCodeState || discountCodeProp;

  const isTrialPurchase = discountCode?.subscription_trial;

  const [applyCredits, setApplyCredits] = useState(true);

  const origPrice = SUBSCRIPTION_PRODUCT_TYPES_CONFIG[product].pricing[plan];
  const { currency, sales_tax } = COUNTRIES[countryCode] || COUNTRIES.US;

  let total = origPrice;

  const discountCodeDiscount = calculateDiscount(discountCode, total);
  total -= discountCodeDiscount;

  const referralDiscount = user.is_referred ? Math.min(REFERRAL_DISCOUNT_AMOUNT, total) : 0;
  total -= referralDiscount;

  const creditedAmount = Math.min(total, user.course_credits || 0);
  if (applyCredits) total -= creditedAmount;

  const subtotal = total;
  const tax = Math.round(total * sales_tax) / 100;
  total = Math.round((total + tax) * 100) / 100;

  const bogoEnabled = false; // intent === 'purchase' && plan === 'monthly'; // -- only enabled end of semester
  const isDiscounted = !!(discountCodeDiscount || referralDiscount || (creditedAmount && applyCredits));

  const paymentDetails = {
    lineItems: [
      { label: 'Subtotal', amount: origPrice, highlight: !isDiscounted },
      discountCodeDiscount && {
        label: `Discount Code: ${discountCode.code}`,
        caption: `${discountCode.name || 'Discount Code'}`,
        amount: -discountCodeDiscount,
      },
      isTrialPurchase && {
        label: `Total due ${formatDate(discountCode.expires_at, 'upcomingDate')}`,
        amount: origPrice,
      },
      referralDiscount && {
        label: 'Refer a Friend Discount',
        amount: -referralDiscount,
      },
      creditedAmount && {
        label: 'Credits',
        amount: -creditedAmount,
        onToggleEnabled: setApplyCredits,
        enabled: applyCredits,
      },
      isDiscounted && {
        label: 'Discounted Subtotal',
        amount: subtotal,
        highlight: true,
      },
      bogoEnabled && {
        label: 'Free additional month!',
        crossOutAmount: origPrice,
        caption: `A free month on us for our Winter Buy-One-Get-One Sale! You won’t be charged your monthly subscription until ${formatDate(
          addDate(null, { months: 2 }),
          'dateShort',
        )}.`,
      },
      tax && { label: `Sales Tax (${sales_tax}%)`, amount: tax },
      { label: 'Total due today', amount: total },
    ].filter(Boolean),
    total,
    tax,
    currency,
  };

  const amountInCents = Math.round(total * 100);
  const completeExistingPayment = paymentIntent?.amount === amountInCents;

  const handlePaymentSuccess = useCallback(
    ({ callback, paymentIntent, paymentMethod }) =>
      onSubmit(
        paymentMethod || paymentIntent,
        callback,
        total,
        plan,
        product,
        discountCode,
        applyCredits,
        completeExistingPayment,
        bogoEnabled,
      ),
    [bogoEnabled, completeExistingPayment, onSubmit, plan, product, total, discountCode, applyCredits],
  );

  return (
    <>
      {intent === 'purchase' && (
        <>
          {isTrialPurchase && (
            <Box sx={{ backgroundColor: 'purple.background', p: 2, borderRadius: 0.5, mb: 3 }}>
              <Typography component="p" sx={{ mb: 1 }} variant="overline">
                Free until {formatDate(discountCode.expires_at, 'upcomingDate')}
              </Typography>
              <Typography>
                {`Whichever plan you select, you won't be charged until ${formatDate(
                  discountCode.expires_at,
                  'upcomingDate',
                )}. Cancel before then and you won't be charged.`}
              </Typography>
            </Box>
          )}
          <DiscountCodeInput
            ButtonProps={{ variant: 'outlined' }}
            defaultQuery={defaultDiscountCodeQuery || discountCode?.code}
            onVerified={setDiscountCode}
            productType="Subscription"
            TextFieldProps={{ placeholder: 'Discount code' }}
            verifyDefaultQuery={!!defaultDiscountCodeQuery}
          />
          <CheckoutLineItems css={({ spacing }) => ({ margin: spacing(3, 0) })} paymentDetails={paymentDetails} />
        </>
      )}
      <PaymentForm
        amount={amountInCents}
        countryCode={countryCode}
        currency={currency}
        disabled={purchasing}
        error={purchaseError}
        onCancel={noDialog && onClose}
        onSuccess={handlePaymentSuccess}
        paymentIntent={(intent === 'retry_payment' || completeExistingPayment) && paymentIntent}
        paymentMethod={paymentMethod}
        submitButtonProps={{
          children:
            intent === 'purchase' ? 'Subscribe'
            : intent === 'update_payment' ? 'Save Info'
            : 'Retry Payment',
        }}
        submitting={purchasing}
      />
      {intent === 'purchase' && (
        <Typography align="center" color="textSecondary" sx={{ display: 'block' }} variant="caption">
          {isTrialPurchase ?
            `By confirming your subscription, you allow Wizeprep to charge your card ${numberToCurrency(
              origPrice,
            )} when your trial expires on ${formatDate(discountCode.expires_at, 'upcomingDate')}`
          : `By confirming your subscription, you allow Wizeprep to charge your card ${numberToCurrency(
              total,
            )} now and future ${plan.replace('ly', '')}s, until you cancel the subscription.`
          }
        </Typography>
      )}
    </>
  );
}

SubscriptionCheckoutPayment.propTypes = {};

export default SubscriptionCheckoutPayment;
