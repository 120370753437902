import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

import useLoadScript from 'my-core/hooks/useLoadScript';

import PaymentForm from './PaymentForm';

const STRIPE_CLIENT_SCRIPT = 'https://js.stripe.com/v3/';

function getStripeAPI(stripeLoaded, countryCode) {
  if (stripeLoaded) {
    const publicKey = countryCode === 'CA' ? process.env.STRIPE_CA_PUBLIC_KEY : process.env.STRIPE_US_PUBLIC_KEY;
    const stripe = window.Stripe(publicKey);
    const elements = stripe.elements();
    return { stripe, elements };
  }
}

function PaymentFormCtnr(props) {
  const {
    amount,
    countryCode,
    currency,
    disabled,
    error,
    includeAffirm,
    onCancel,
    onChangePaymentOption,
    onChangeSuccessHandler,
    onError,
    onSuccess,
    paymentIntent,
    paymentMethod,
    paymentOption,
    submitButtonProps,
    submitting,
  } = props;

  const [stripeLoaded, setStripeLoaded] = useState(useLoadScript.loadedScripts[STRIPE_CLIENT_SCRIPT]);
  const [stripeAPI, setStripeAPI] = useState(() => getStripeAPI(stripeLoaded, countryCode));

  useLoadScript(STRIPE_CLIENT_SCRIPT, {
    onLoad: () => setStripeLoaded(true),
    onError: () => console.error('Stripe could not be loaded'),
  });

  // console.log(stripePlan);

  useEffect(() => {
    if (!stripeAPI) setStripeAPI(getStripeAPI(stripeLoaded, countryCode));
  }, [countryCode, stripeAPI, stripeLoaded]);

  return (
    <PaymentForm
      {...stripeAPI}
      amount={amount}
      countryCode={countryCode}
      currency={currency}
      disabled={disabled || !stripeAPI}
      error={error}
      includeAffirm={includeAffirm}
      onCancel={onCancel}
      onChangePaymentOption={onChangePaymentOption}
      onChangeSuccessHandler={onChangeSuccessHandler}
      onError={onError}
      onSuccess={onSuccess}
      paymentIntent={paymentIntent}
      paymentMethod={paymentMethod}
      paymentOption={paymentOption}
      submitButtonProps={submitButtonProps}
      submitting={submitting}
    />
  );
}

export default PaymentFormCtnr;

PaymentFormCtnr.propTypes = {
  amount: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]).isRequired, // should only be false while disabled
  countryCode: PropTypes.string,
  currency: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.bool]),
  paymentIntent: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  paymentMethod: PropTypes.object,
  onSuccess: PropTypes.func, //.isRequired,
  submitButtonProps: PropTypes.object,
  // purchasing: PropTypes.bool
};
