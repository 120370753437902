import { insertScript } from 'my-core/script-utils';

const SCRIPT_URL_CA =
  __DEV__ ? 'https://cdn1-sandbox.affirm.ca/js/v2/affirm.js' : 'https://cdn1.affirm.ca/js/v2/affirm.js';
const SCRIPT_URL_US =
  __DEV__ ? 'https://cdn1-sandbox.affirm.com/js/v2/affirm.js' : 'https://cdn1.affirm.com/js/v2/affirm.js';

let initialized = false;
export function initializeAffirm(countryCode) {
  if (initialized) return;
  initialized = true;
  const config =
    countryCode === 'CA' ?
      {
        public_api_key: process.env.AFFIRM_PUBLIC_KEY,
        locale: 'en_CA',
        country_code: 'CAN',
      }
    : {
        public_api_key: process.env.AFFIRM_US_PUBLIC_KEY,
        locale: 'en_US',
        country_code: 'USA',
      };

  const script = countryCode === 'CA' ? SCRIPT_URL_CA : SCRIPT_URL_US;

  const affirmObj = window.affirm || {};
  const createFunction = function (a, b, c) {
    return function () {
      a[b]._.push([c, arguments]);
    };
  };
  affirmObj.checkout = createFunction(affirmObj, 'checkout', 'set');
  var checkoutFn = affirmObj.checkout;
  affirmObj.ui = {};
  affirmObj.ui._ = [];
  checkoutFn._ = [];
  affirmObj._ = [];
  affirmObj.ui.ready = createFunction(affirmObj, 'ui', 'ready');
  affirmObj.jsReady = function () {
    affirmObj._.push(['ready', arguments]);
  };
  'set add save post open empty reset on off trigger ready setProduct'.split(' ').forEach(key => {
    checkoutFn[key] = createFunction(affirmObj, 'checkout', key);
  });
  [('get', 'token', 'url', 'items')].forEach(key => {
    checkoutFn[key] = function () {};
  });
  insertScript(script);

  checkoutFn(config);
  window.affirm = affirmObj;
}

// (function(m,g,n,d,a,e,h,c){var b=m[n]||{},k=document.createElement(e),p=document.getElementsByTagName(e)[0],l=function(a,b,c){return function(){a[b]._.push([c,arguments])}};b[d]=l(b,d,"set");var f=b[d];b[a]={};b[a]._=[];f._=[];b._=[];b[a][h]=l(b,a,h);b[c]=function(){b._.push([h,arguments])};a=0;for(c="set add save post open empty reset on off trigger ready setProduct".split(" ");a<c.length;a++)f[c[a]]=l(b,d,c[a]);a=0;for(c=["get","token","url","items"];a<c.length;a++)f[c[a]]=function(){};k.async=
//   !0;k.src=g[e];p.parentNode.insertBefore(k,p);delete g[e];f(g);m[n]=b})(window,_affirm_config,"affirm","checkout","ui","script","ready","jsReady");

export function affirmCheckoutObject(user, address, purchaseInfo) {
  return {
    merchant: {
      public_api_key: user.country_code === 'CA' ? process.env.AFFIRM_PUBLIC_KEY : process.env.AFFIRM_US_PUBLIC_KEY,
      user_cancel_url: `https://${window.location.origin}/affirm/cancel`,
      user_confirmation_url: `https://${window.location.origin}/affirm/confirm`,
      // user_confirmation_url_action: 'POST', // optional
      // use_vcn: true, // optional
      // name: 'Wizeprep', // optional
    },
    // shipping: {
    //   name: { full: 'Mikeal Mishnovitz' },
    //   address: {
    //     line1: '633 Folsom St',
    //     line2: 'Floor 7',
    //     city: 'San Francisco',
    //     state: 'CA',
    //     zipcode: '94107',
    //     country: 'USA',
    //   },
    // },
    // shipping: {
    //   name: {
    //     full: user.name,
    //   },
    //   address: {
    //     city: address.city,
    //     ...(address.country_code === 'CA' ?
    //       {
    //         street1: address.address_line_1,
    //         street2: address.address_line_2,
    //         region1_code: address.region_code,
    //         postal_code: address.postal_code,
    //         country: 'CAN',
    //       }
    //     : {
    //         line1: address.address_line_1,
    //         line2: address.address_line_2,
    //         state: address.region_code,
    //         zipcode: address.postal_code,
    //         country: 'USA',
    //       }),
    //   },
    //   // phone_number: '250-555-0199',
    //   email: user.email,
    // },
    items: purchaseInfo.items,
    discounts: purchaseInfo.discounts,
    // checkoutAri: 'xxx',
    metadata: { mode: 'modal' },
    // order_id: '00de5cee-7226-4aec-b729-a571f773a58c', // optional
    // shipping_amount: 2500,
    tax_amount: Math.round(purchaseInfo.taxAmount * 100),
    total: Math.round(purchaseInfo.total * 100),
    currency: purchaseInfo.currency,
  };
}
