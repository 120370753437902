import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { initializeAffirm } from 'my-core/affirm';
// import { numberToCurrency } from 'my-utils';
import {
  checkoutBrowserPaymentClicked,
  checkoutBrowserPaymentEnabled,
  checkoutCardPaymentAttempted,
  checkoutExistingCardPaymentAttempted,
} from 'my-core/gtm-events';

import ErrorMessage from 'my-elements/ErrorMessage';

import PaymentFormCardFields from './PaymentFormCardFields';

import Button from '@mui/material/Button';
import ButtonBase from '@mui/material/ButtonBase';
import Collapse from '@mui/material/Collapse';
// import DialogActions from '@mui/material/DialogActions';
// import DialogContent from '@mui/material/DialogContent';
import Divider from '@mui/material/Divider';
import Radio from '@mui/material/Radio';
import Typography from '@mui/material/Typography';

import applePay from 'images/third-party/apple-pay.svg';
import amexDark from 'images/third-party/credit-cards/amex-dark.png';
import amexLight from 'images/third-party/credit-cards/amex-light.png';
import masterCardDark from 'images/third-party/credit-cards/master-card-dark.png';
import masterCardLight from 'images/third-party/credit-cards/master-card-light.png';
import visaDark from 'images/third-party/credit-cards/visa-dark.png';
import visaLight from 'images/third-party/credit-cards/visa-light.png';
import googlePay from 'images/third-party/google-pay.svg';

const CARD_ICON_MAP = [
  ['visa', visaLight, visaDark],
  ['american-express', amexLight, amexDark],
  ['mastercard', masterCardLight, masterCardDark],
];
const AFFIRM_DOM_ID = 'affirm_promo';

const useStyles = makeStyles(
  ({ palette, shape: { borderRadius }, spacing }) => ({
    errorMessage: {},
    authWrapper: {
      padding: spacing(3),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    authorizeBtn: {
      minWidth: 200,
    },
    radio: {
      padding: 0,
      marginRight: spacing(1.5),
      '&:hover': { backgroundColor: 'transparent' },
    },
    cardIcon: {
      width: 36,
      marginLeft: spacing(0.5),
      verticalAlign: 'bottom',
      borderRadius,
      border: [[1, 'solid', palette.grey[200]]],
    },
    tpPaymark: { height: 20, width: 'auto' },
    content: {
      marginTop: spacing(2),
      border: [[1, 'solid', palette.divider]],
      // borderTop: 0,
      borderRadius,
      overflow: 'hidden',
    },
    paymentOption: {
      textAlign: 'left',
      width: '100%',
      display: 'flex',
      padding: spacing(1, 2),
      backgroundColor: palette.background.paper,
      '& ~ &': { borderTop: [[1, 'solid', palette.divider]] },
    },
    paymentOption_disabled: {
      '& > *': { opacity: 0.5 },
    },
    spacer: { flex: 1 },
    actions: {
      '& > *': { display: 'block', margin: spacing(2, 'auto'), width: '100%' },
    },
    submitBtn: {},
  }),
  { name: 'PaymentForm' },
);

function PaymentForm(props) {
  const classes = useStyles(props);
  const {
    amount,
    countryCode,
    currency,
    disabled,
    elements,
    error,
    includeAffirm,
    onCancel,
    onChangePaymentOption,
    onChangeSuccessHandler,
    onError,
    onSuccess,
    paymentIntent: paymentIntentProp,
    paymentMethod,
    paymentOption: paymentOptionProp,
    stripe,
    submitButtonProps,
    submitting,
  } = props;

  const paymentIntent =
    ['requires_action', 'requires_payment_method'].includes(paymentIntentProp?.status) && paymentIntentProp;

  const [paymentOptionState, setPaymentOptionState] = useState(paymentMethod?.card ? 'existing-pm' : 'card');
  const paymentOption = paymentOptionProp || paymentOptionState;
  const setPaymentOption = onChangePaymentOption || setPaymentOptionState;
  // https://react.dev/learn/you-might-not-need-an-effect#adjusting-some-state-when-a-prop-changes
  // const [prevPaymentMethodCard, setPrevPaymentMethodCard] = useState(paymentMethod?.card);
  // if (prevPaymentMethodCard !== paymentMethod?.card) {
  //   setPrevPaymentMethodCard(paymentMethod?.card);
  //   if (paymentOption === 'card' && paymentMethod?.card) setPaymentOption('existing-pm');
  // }
  useEffect(
    () => {
      if (paymentOption === 'card' && paymentMethod?.card) setPaymentOption('existing-pm');
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [paymentMethod?.card],
  );

  const [browserPaymentConfig, setBrowserPaymentConfig] = useState(false);

  const [stripeError, setStripeError] = useState();

  const [stripeProcessing, setStripeProcessing] = useState(false);
  const [authDisabled, setAuthDisabled] = useState(false);
  const [autoAuth, setAutoAuth] = useState(false);

  const [cardType, setCardType] = useState('');
  const [cardValidationErrors, setCardValidationErrors] = useState({ _default: true });
  const updateValidationErrors = useCallback((key, val) => {
    setCardValidationErrors(errs => ({ ...errs, _default: false, [key]: val }));
  }, []);
  const [billingDetails, setBillingDetails] = useState({});
  const stripeElementRef = useRef();

  const authorizePaymentIntent = useCallback(
    (onSuccess, paymentIntent, onComplete, onError) => {
      if (stripe) {
        setStripeProcessing(true);
        setAuthDisabled(false);
        stripe.confirmCardPayment(paymentIntent.client_secret).then(payload => {
          setStripeProcessing(false);
          if (payload.error) {
            setAuthDisabled(true);
            setStripeError(payload.error);
            onError?.(payload.error);
            // The payment failed -- ask your customer for a new payment method.
          } else {
            // The payment has succeeded.
            onSuccess({ paymentIntent: payload.paymentIntent });
          }
          onComplete?.();
        });
      } else {
        onComplete?.();
      }
    },
    [stripe],
  );

  // For built-in browser payment
  const paymentRequest = useMemo(() => {
    if (stripe) {
      const pr = stripe.paymentRequest({
        currency: currency.toLowerCase(),
        country: VALID_COUNTRY_CODES.includes(countryCode) ? countryCode : 'US',
        total: { amount: Math.round(amount), label: 'default' },
        requestPayerName: true,
      });
      return pr;
    }
  }, [amount, countryCode, currency, stripe]);

  useEffect(() => {
    if (!paymentRequest) {
      setBrowserPaymentConfig(false);
      return;
    }
    let mounted = true;
    //canMakePayment resolves to null if no API is available
    paymentRequest.canMakePayment().then(res => {
      if (mounted) {
        setBrowserPaymentConfig(res);
        if (res) checkoutBrowserPaymentEnabled();
      }
    });
    return () => {
      mounted = false;
    };
  }, [paymentRequest]);

  const submitPayment = useCallback(
    (onSuccess, paymentIntent, onComplete, onError) => {
      if (stripeProcessing) return;
      setStripeError(null);
      switch (paymentOption) {
        case 'existing-pm': {
          checkoutExistingCardPaymentAttempted();
          if (!paymentIntent) {
            onSuccess({ paymentMethod: paymentMethod.id });
            setAutoAuth(true);
            onComplete?.();
          } else {
            setStripeProcessing(true);
            stripe
              .confirmCardPayment(paymentIntent.client_secret, { payment_method: paymentMethod.id })
              .then(payload => {
                setStripeProcessing(false);
                if (payload.error) {
                  setStripeError(payload.error);
                  onError?.(payload.error); // code, decline_code, message, param, type
                } else {
                  setStripeError(null);
                  onSuccess({ paymentIntent: payload.paymentIntent });
                }
                onComplete?.();
              });
          }
          return;
        }
        case 'card': {
          checkoutCardPaymentAttempted();
          setStripeProcessing(true);
          const fixedBillingDetails = {
            name: billingDetails?.name,
            address: { postal_code: billingDetails?.postal_code },
          };
          if (!paymentIntent) {
            stripe
              .createPaymentMethod({
                type: 'card',
                billing_details: fixedBillingDetails,
                card: stripeElementRef.current,
              })
              .then(payload => {
                setStripeProcessing(false);
                if (payload.error) {
                  setStripeError(payload.error);
                  onError?.(payload.error);
                } else {
                  setStripeError(null);
                  onSuccess({ paymentMethod: payload.paymentMethod.id });
                  setAutoAuth(true);
                }
                onComplete?.();
              });
          } else {
            stripe
              .confirmCardPayment(paymentIntent.client_secret, {
                payment_method: {
                  billing_details: fixedBillingDetails,
                  card: stripeElementRef.current,
                },
              })
              .then(payload => {
                setStripeProcessing(false);
                if (payload.error) {
                  setStripeError(payload.error);
                  onError?.(payload.error);
                } else {
                  setStripeError(null);
                  onSuccess({ paymentIntent: payload.paymentIntent });
                }
                onComplete?.();
              });
          }
          return;
        }
        case 'browser': {
          paymentRequest.once('cancel', () => {
            setPaymentOption('card');
            paymentRequest.off('paymentmethod');
            onComplete?.();
          });
          paymentRequest.once('paymentmethod', res => {
            setStripeError(null);
            const pm = res.paymentMethod;
            if (!paymentIntent) {
              onSuccess({ paymentMethod: pm.id });
              onComplete?.();
            } else {
              setStripeProcessing(true);
              // https://stripe.com/docs/stripe-js/elements/payment-request-button#html-js-complete-payment
              stripe
                .confirmCardPayment(paymentIntent.client_secret, { payment_method: pm.id }, { handleActions: false })
                .then(payload => {
                  if (payload.error) {
                    setStripeError(payload.error);
                    setStripeProcessing(false);
                    onError?.(payload.error);
                    onComplete?.();
                  } else {
                    if (payload.paymentIntent.status === 'requires_action') {
                      // Let Stripe.js handle the rest of the payment flow.
                      authorizePaymentIntent(onSuccess, paymentIntent, onComplete, onError);
                    } else {
                      // The payment has succeeded.
                      setStripeProcessing(false);
                      onSuccess({ paymentIntent: payload.paymentIntent });
                      onComplete?.();
                    }
                  }
                });
            }

            res.complete('success');
          });
          paymentRequest.show();
        }
      }
    },
    [
      authorizePaymentIntent,
      billingDetails?.name,
      billingDetails?.postal_code,
      paymentMethod?.id,
      paymentOption,
      paymentRequest,
      setPaymentOption,
      stripe,
      stripeProcessing,
    ],
  );

  useEffect(() => {
    if (autoAuth && paymentIntent?.status === 'requires_action') {
      authorizePaymentIntent(onSuccess, paymentIntent, undefined, onError);
    }
  }, [authorizePaymentIntent, onSuccess, paymentIntent, autoAuth, onError]);

  function handlePaymentOptionSelected(option) {
    setPaymentOption(option);
    switch (option) {
      case 'browser':
        checkoutBrowserPaymentClicked();
        break;
    }
  }

  const disableFields = disabled || stripeProcessing;

  const isFormValid =
    !disabled &&
    !stripeProcessing &&
    (paymentOption === 'existing-pm' ||
      paymentOption === 'browser' ||
      (includeAffirm && paymentOption === 'affirm') ||
      (paymentOption === 'card' &&
        !Object.values(cardValidationErrors).some(Boolean) &&
        billingDetails.name &&
        billingDetails.postal_code));

  useEffect(() => {
    if (!onChangeSuccessHandler) return;
    if (!isFormValid) return onChangeSuccessHandler();
    onChangeSuccessHandler({ handler: submitPayment });
  }, [isFormValid, onChangeSuccessHandler, submitPayment]);
  useEffect(() => {
    if (!onChangeSuccessHandler) return;
    return () => onChangeSuccessHandler();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!includeAffirm) return;
    initializeAffirm(countryCode);
    window.affirm.jsReady(() => {
      const comp = window.affirm.ui.components.create('promo', {
        amount: amount,
        pageType: 'product',
      });
      comp.render(`#${AFFIRM_DOM_ID}`);
    });
  }, [amount, includeAffirm]);

  return (
    <>
      <div>
        {(error || stripeError) && (
          <ErrorMessage color="secondary" error={stripeError?.message || error} variant="body2" />
        )}
        {!authDisabled && paymentIntent?.status === 'requires_action' ?
          <div className={classes.authWrapper}>
            <Typography gutterBottom>
              **** **** **** {paymentMethod.card.last4}
              <img
                className={classes.cardIcon}
                src={CARD_ICON_MAP.find(([k]) => k === paymentMethod.card.brand)?.[1]}
              />
            </Typography>
            <Button
              className={classes.authorizeBtn}
              disabled={disableFields}
              onClick={() => authorizePaymentIntent(onSuccess, paymentIntent, undefined, onError)}
              size="large"
              variant="contained"
            >
              Authorize
            </Button>
            <Button disabled={disableFields} onClick={() => setAuthDisabled(true)}>
              Use Other Card
            </Button>
          </div>
        : <div className={classes.content}>
            {paymentMethod?.card &&
              renderPanel(
                'existing-pm',
                <>
                  <Typography>Existing Card</Typography>
                  <div className={classes.spacer} />
                  <Typography>**** {paymentMethod.card.last4}</Typography>
                  <img
                    className={classes.cardIcon}
                    src={CARD_ICON_MAP.find(([k]) => k === paymentMethod.card.brand)?.[1]}
                  />
                </>,
              )}
            {renderPanel(
              'card',
              <>
                <Typography>Credit Card</Typography>
                <div className={classes.spacer} />
                {CARD_ICON_MAP.map(t => (
                  <img key={t[0]} className={classes.cardIcon} src={cardType === t[0] ? t[2] : t[1]} />
                ))}
              </>,
              <PaymentFormCardFields
                billingDetails={billingDetails}
                disableFields={disableFields}
                elements={elements}
                onChangeBillingDetails={setBillingDetails}
                onChangeCardType={setCardType}
                onChangeValidationErrors={updateValidationErrors}
                stripeElementRef={stripeElementRef}
                validationErrors={cardValidationErrors}
              />,
            )}
            {browserPaymentConfig &&
              renderPanel(
                'browser',
                <>
                  <Typography>
                    {browserPaymentConfig.applePay ?
                      'Pay with Apple Pay'
                    : browserPaymentConfig.googlePay ?
                      'Pay with Google Pay'
                    : 'Use browser credentials'}
                  </Typography>
                  <div className={classes.spacer} />
                  {browserPaymentConfig.applePay ?
                    <img alt="apple pay mark" className={classes.tpPaymark} src={applePay} />
                  : browserPaymentConfig.googlePay && (
                      <img alt="google pay mark" className={classes.tpPaymark} src={googlePay} />
                    )
                  }{' '}
                </>,
              )}
            {includeAffirm &&
              renderPanel(
                'affirm',
                <>
                  <Typography id={AFFIRM_DOM_ID}>Pay in installments with Affirm</Typography>
                  <div className={classes.spacer} />
                </>,
              )}
          </div>
        }
      </div>
      <Typography color="textSecondary" variant="caption">
        All transactions are secure and encrypted.
      </Typography>
      <div className={classes.actions}>
        {!onChangeSuccessHandler && (
          <Button
            children="Complete Payment" // eslint-disable-line @eslint-react/no-children-prop
            disabled={!isFormValid}
            onClick={() => submitPayment(onSuccess, paymentIntent, undefined, onError)}
            size="large"
            variant="contained"
            {...submitButtonProps}
          />
        )}
        {onCancel && (
          <Button color="secondary" disabled={!!(stripeProcessing || submitting)} onClick={onCancel} variant="outlined">
            Cancel
          </Button>
        )}
      </div>
    </>
  );

  function renderPanel(value, title, content) {
    const active = paymentOption === value;
    return (
      <>
        <ButtonBase
          classes={{
            root: classes.paymentOption,
            disabled: classes.paymentOption_disabled,
          }}
          disabled={disableFields}
          disableRipple
          onClick={() => handlePaymentOptionSelected(value)}
        >
          <Radio
            checked={active}
            className={classes.radio}
            color="primary"
            disableFocusRipple
            disableRipple
            disableTouchRipple
            name={`payment-option-${value}`}
            value={value}
          />
          {title}
        </ButtonBase>
        {Boolean(content) && (
          <Collapse in={active}>
            <Divider />
            {content}
          </Collapse>
        )}
      </>
    );
  }
}

const VALID_COUNTRY_CODES = [
  'AE',
  'AT',
  'AU',
  'BE',
  'BG',
  'BR',
  'CA',
  'CH',
  'CI',
  'CR',
  'CY',
  'CZ',
  'DE',
  'DK',
  'DO',
  'EE',
  'ES',
  'FI',
  'FR',
  'GB',
  'GI',
  'GR',
  'GT',
  'HK',
  'HU',
  'ID',
  'IE',
  'IN',
  'IT',
  'JP',
  'LI',
  'LT',
  'LU',
  'LV',
  'MT',
  'MX',
  'MY',
  'NL',
  'NO',
  'NZ',
  'PE',
  'PH',
  'PL',
  'PT',
  'RO',
  'SE',
  'SG',
  'SI',
  'SK',
  'SN',
  'TH',
  'TT',
  'US',
  'UY',
];

PaymentForm.propTypes = {
  amount: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]).isRequired,
  countryCode: PropTypes.string,
  currency: PropTypes.string.isRequired,
  elements: PropTypes.object,
  error: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.bool]),
  onSuccess: PropTypes.func,
  onError: PropTypes.func,
  purchasing: PropTypes.bool,
  stripe: PropTypes.object,
};

export default PaymentForm;
