import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { numberToCurrency } from 'my-utils';

import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import { faPlus, faTag, faTrash } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const useStyles = makeStyles(
  ({ palette, spacing }) => ({
    root: {},
    lineItem: {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      '& + &': { marginTop: spacing(1) },
    },
    lineItem_disabled: { opacity: 0.6 },
    toggleBtn: {
      position: 'absolute',
      right: '100%',
      top: '50%',
      transform: `translate(${spacing(-0.5)}, -50%)`,
    },
    amount: {
      marginLeft: 'auto',
      flexShrink: 0,
      whiteSpace: 'nowrap',
      '$lineItem_disabled &': { textDecoration: 'line-through' },
    },
    crossOutAmount: {
      textDecoration: 'line-through',
      color: palette.secondary.main,
    },
  }),
  { name: 'CheckoutLineItems' },
);

function CheckoutLineItems(props) {
  const { className, paymentDetails, totalsRows = 1 } = props;
  const classes = useStyles(props);
  return (
    <div className={classNames(classes.root, className)}>
      {paymentDetails.lineItems
        .slice(0, -totalsRows)
        .map(({ amount, caption, crossOutAmount, enabled, highlight, label, onToggleEnabled }, idx) => (
          <div
            key={idx}
            className={classNames(classes.lineItem, { [classes.lineItem_disabled]: onToggleEnabled && !enabled })}
          >
            {onToggleEnabled && (
              <IconButton className={classes.toggleBtn} onClick={() => onToggleEnabled(!enabled)} size="small">
                <FontAwesomeIcon icon={enabled ? faTrash : faPlus} size="xs" />
              </IconButton>
            )}
            <Typography
              color={
                amount < 0 ? 'success.main'
                : highlight ?
                  'text.primary'
                : 'text.secondary'
              }
              component="span"
              sx={{ pr: 2 }}
              variant="body1"
            >
              {amount < 0 && (
                <>
                  <FontAwesomeIcon icon={faTag} />{' '}
                </>
              )}
              {label}
              {caption && (
                <Typography color="inherit" sx={{ display: 'block', opacity: 0.7, lineHeight: 1 }} variant="caption">
                  {caption}
                </Typography>
              )}
            </Typography>
            <Typography
              className={classes.amount}
              color={amount < 0 ? 'success.main' : 'inherit'}
              component="span"
              variant={highlight ? 'subtitle1' : 'body1'}
            >
              {!!crossOutAmount && (
                <>
                  <span className={classes.crossOutAmount}>{numberToCurrency(crossOutAmount)}</span>{' '}
                </>
              )}
              {numberToCurrency(amount, { forceCents: true })}
            </Typography>
          </div>
        ))}
      <Divider sx={{ my: 2 }} />
      {paymentDetails.lineItems.slice(-totalsRows).map(({ amount, label }) => (
        <div key={label} className={classes.lineItem}>
          <Typography component="span" variant="h6">
            {label}
          </Typography>
          <Typography className={classes.amount} color="textSecondary" component="span" variant="body2">
            {`${paymentDetails.currency.toUpperCase()} `}
            <Typography color="textPrimary" component="span" variant="h5">
              {numberToCurrency(amount, { forceCents: true })}
            </Typography>
          </Typography>
        </div>
      ))}
    </div>
  );
}

CheckoutLineItems.propTypes = {
  paymentDetails: PropTypes.object.isRequired,
};

export default CheckoutLineItems;
