import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Link } from 'react-router';

import { WIZE_SUBSCRIPTION_NAME } from 'my-core/constants';
import { preMedPlusLandingPath } from 'my-core/routes';
import { DEFAULT_PLAN, DEFAULT_PRODUCT, PROMO_POINTS, YEARLY_DISCOUNT_PERCENT } from 'my-core/subscription-utils';
import { getUserFirstName } from 'my-core/user-utils';

import { SubscriptionPlanItems } from 'my-components/Subscription';
import DialogResponsive from 'my-elements/DialogResponsive';
import Label from 'my-elements/Label';
import ToggleSwitch from 'my-elements/ToggleSwitch';

import SubscriptionCheckoutPayment from './SubscriptionCheckoutPayment';
import SubscriptionCheckoutProductOptions from './SubscriptionCheckoutProductOptions';

import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
// import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';

import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const useStyles = makeStyles(
  ({ breakpoints, palette, shape, spacing }) => ({
    root: {
      position: 'relative',
      maxWidth: 700,
      padding: spacing(2, 4),
    },
    closeBtn: {
      position: 'absolute',
      right: spacing(1),
      top: spacing(1),
    },
    header: {
      padding: spacing(2, 0, 4),
    },
    plans: {
      marginTop: spacing(2),
      justifyContent: 'space-around',
    },

    points: {
      margin: spacing(2, 0, 0),
    },
    point: {
      display: 'flex',
      alignItems: 'flex-start',
      marginBottom: spacing(1),

      '& > svg': {
        marginTop: 2,
        marginRight: spacing(2),
        color: palette.text.secondary,
      },
    },
    planToggleWrapper: { display: 'flex', alignItems: 'center', gap: spacing(2) },
    preMedCallout: {
      marginTop: spacing(2),
      borderRadius: shape.borderRadiusSm,
      backgroundColor: palette.background.default,
      padding: spacing(1, 3),
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      gap: spacing(1),
      [breakpoints.down(420)]: { flexDirection: 'column' },
    },

    [breakpoints.only('xs')]: {
      points: { marginBottom: spacing(3) },
    },
  }),
  { name: 'SubscriptionCheckoutDialog' },
);

export default function SubscriptionCheckoutDialog(props) {
  const {
    defaultDiscountCodeQuery,
    defaultPlan,
    defaultProduct,
    discountCode,
    intent,
    noDialog,
    onClose,
    onSubmit,
    open,
    paymentIntent,
    paymentMethod,
    purchaseError,
    purchasing,
    subscription,
    successPage,
    user,
  } = props;
  const classes = useStyles(props);

  const [plan, setPlan] = useState(defaultPlan || DEFAULT_PLAN);
  useEffect(() => {
    if (open && defaultPlan) setPlan(defaultPlan);
  }, [open, defaultPlan]);

  const showPreMedPlusOption = !!defaultProduct;
  const [productState, setProduct] = useState(defaultProduct || DEFAULT_PRODUCT);
  const product = showPreMedPlusOption ? productState : DEFAULT_PRODUCT;

  return noDialog ? renderContents() : (
      <DialogResponsive
        classes={{ paper: classes.root }}
        disableMobileClose
        fullWidth
        onClose={successPage ? onClose : undefined}
        open={open}
      >
        <IconButton className={classes.closeBtn} disabled={purchasing} onClick={onClose}>
          <FontAwesomeIcon icon={faTimes} />
        </IconButton>
        {renderContents()}
      </DialogResponsive>
    );

  function renderContents() {
    return (
      <>
        <div className={classes.header}>{renderHeaderContent()}</div>
        <div>
          {successPage ?
            renderConfirmation()
          : <SubscriptionCheckoutPayment
              defaultDiscountCodeQuery={defaultDiscountCodeQuery}
              discountCode={discountCode}
              intent={intent}
              noDialog={noDialog}
              onClose={onClose}
              onSubmit={onSubmit}
              paymentIntent={paymentIntent}
              paymentMethod={paymentMethod}
              plan={plan}
              product={product}
              purchaseError={purchaseError}
              purchasing={purchasing}
              user={user}
            />
          }
        </div>
      </>
    );
  }

  function renderHeaderContent() {
    switch (intent) {
      case 'update_payment':
      case 'retry_payment': {
        if (successPage) {
          return (
            <Typography align="center" variant="h4">
              {intent === 'update_payment' ? 'Payment Info Updated' : 'Payment Succeeded'}
            </Typography>
          );
        }
        return (
          <Typography align="center" variant="h4">
            Update Payment Info
          </Typography>
        );
      }
    }
    if (successPage) {
      return (
        <Typography align="center" variant="h4">
          Congrats {getUserFirstName(user)} 🎉
        </Typography>
      );
    }
    if (showPreMedPlusOption) {
      return (
        <>
          <Typography sx={{ mb: 2 }} variant="h4">
            Subscription
          </Typography>
          <div className={classes.planToggleWrapper}>
            <ToggleSwitch
              onChange={setPlan}
              toggle1Label="Monthly"
              toggle1Value="monthly"
              toggle2Label="Yearly"
              toggle2Value="yearly"
              value={plan}
            />
            {plan === 'yearly' && (
              <Label color="green" label={`${YEARLY_DISCOUNT_PERCENT.toFixed()}% cheaper`} variant="light" />
            )}
          </div>
          <SubscriptionCheckoutProductOptions onSelectProduct={setProduct} plan={plan} product={product} />
        </>
      );
    }
    return (
      <>
        <div>
          <Typography variant="h4">Wizeprep Plus</Typography>
          <div className={classes.points}>
            {PROMO_POINTS.map(point => (
              <div key={point} className={classes.point}>
                <FontAwesomeIcon icon={faCheck} size="lg" />
                <Typography>{point}</Typography>
              </div>
            ))}
          </div>
        </div>
        <SubscriptionPlanItems
          className={classes.plans}
          countryCode={user.country_code}
          onClick={plan => setPlan(plan)}
          selectedPlan={plan}
        />
        <div className={classes.preMedCallout}>
          <Typography variant="body2">Are you pre-med?</Typography>
          <Button component={Link} disabled={purchasing} to={preMedPlusLandingPath()}>
            Check Out Pre-Med Plus
          </Button>
        </div>
      </>
    );
  }

  function renderConfirmation() {
    const btnProps = {
      onClick: onClose,
      size: 'large',
      variant: 'contained',
      sx: { display: 'block', mt: 3, width: { xs: '100%', sm: 'auto' }, mx: 'auto' },
    };
    switch (intent) {
      case 'retry_payment': {
        return (
          <>
            <Typography align="center" paragraph>
              Your subscription access has been re-activated!
            </Typography>
            <Button {...btnProps}>Continue</Button>
          </>
        );
      }
      case 'update_payment': {
        return (
          <>
            <Typography align="center" paragraph>
              Your new info will be used the next time an invoice for you subscription comes due.
            </Typography>
            <Button {...btnProps}>Close</Button>
          </>
        );
      }
      default:
        return (
          <>
            <Typography align="center" paragraph>
              You have successfully unlocked {subscription?.is_premed_plus ? 'Pre-Med Plus' : WIZE_SUBSCRIPTION_NAME}.
            </Typography>
            <Typography align="center">
              {subscription?.is_premed_plus ?
                'Enjoy unlimited access to on-demand lessons & practice, exclusive Pre-Med pre-requisite courses, monthly coaching, special events, expert tutors for Q&A, and more.'
              : 'Enjoy unlimited access to on-demand lessons & practice, expert tutors for Q&A, and downloadable study guides.'
              }
            </Typography>
            <Button {...btnProps}>Get Started</Button>
          </>
        );
    }
  }
}
SubscriptionCheckoutDialog.propTypes = {
  intent: PropTypes.oneOf(['retry_payment', 'update_payment', 'purchase']).isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  open: PropTypes.bool,
  purchaseError: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.bool]),
  purchasing: PropTypes.bool,
  successPage: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
};
